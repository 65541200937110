import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Popup from 'reactjs-popup';
import settings from '../../settings';

const closeIcon =  `${settings.IMAGES_BASE_URL}/images/assets/close_btn_black.svg`;

const SchedulePopup = (props) => {
  return (
  <Popup  onClose={props.onClose} open={props.open} trigger={props.btnText ? <button className={`btn btn-bordered ${props.btnClassName ? props.btnClassName: ''}`}>{props.btnText}</button> : props.button} modal closeOnDocumentClick>
      {(close) => (
        <div className="schedulemodal">
          <div className={`schedulemodal__header ${props.title || props.subtitle ? "mb_10" : ""}`}>
            <div className='schedulemodal__close_btn_wrapper'>
              <button
                  className="schedulemodal__close"
                  onClick={() => {
                    close();
                  }} onKeyDown={close}>
                  <LazyLoadImage effect="blur" src={closeIcon} alt="close" className="close-icon" />
                </button>
            </div>
            <div className="schedulemodal__wrapper">
              <h6>{props.title}</h6>
            </div>
              {props.subtitle &&
                <small>{props.subtitle ? props.subtitle : "Sample schedule subject to change. Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details."}</small>}

          </div>
          <div className="schedulemodal__body">
            {props.children}
          </div>
        </div>
      )}
    </Popup>
  );
};

export default SchedulePopup;
